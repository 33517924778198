<template>
    <section class="cream-background">
        <pageLoader v-if="loadershow" :loadingText="'Traitement des données en cours'"/>
        <div v-else class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <h1>Réservation {{'de'+ name}}</h1>
                                <p class="mt-1">Ajouter la prestation, choisissez la date et l'heure, validez.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-flex justify-content-end">
                                    <!-- <add-service @updateService="addService"/> -->
                                    <!-- Ecran large -->
                                    <add-service class="computer"
                                    :modalId="'addservice1'"
                                    text="Ajouter un rendez-vous"
                                    key="addservice1"
                                    @updateService="addService"
                                    />
                                    <!-- mobile -->
                                    <add-service class="mobile" v-if="this.services.length==0"
                                    :modalId="'addservice2'"
                                    text="Ajouter un rendez-vous"
                                    key="addservice2"
                                    @updateService="addService"
                                    />
                                </div>
                                <!--tableau reservation écran large -->
                                <div class="table-responsive computer">
                                    <table class="table">
                                        <thead>
                                            <th>Prestation</th>
                                            <th>Durée</th>
                                            <th>Tarif</th>
                                            <th>Action</th>
                                        </thead>
                                        <tbody v-if="!services.length">
                                            <tr>
                                                <td class="text-muted">Veuillez choisir une prestation</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr v-for="(item, itemid) in services" :key="itemid">
                                                <td>{{item.name}}</td>
                                                <td class="d-flex">
                                                    <span>{{calculTimes(item.duration)}}</span>
                                                    <!-- modification de la durée -->
                                                    <edit-duration :index="itemid" :modalKey="'edit'+itemid" :name="item.name" :duration="calculTimes(item.duration)"
                                                    @editDuration="editDuration"
                                                    />
                                                </td>
                                                <td style="text-align:right">{{item.price}} €</td>
                                                <td >
                                                    <span class="d-flex">
                                                        <button class="btn btn-primary btn-sm mx-3" style="background: #cda525;color: #ffffff;border:none" @click="removeService(item)">
                                                            <i class="fa fa-trash pointer" ></i>
                                                        </button>
                                                        <!-- <add-service-plus  v-if="checkDate(item)"   :selectedDateTime="item.datepurchase.substr(0,10)" :modalKey="itemid" :key="'plus'+skey" :myDate="myDate" :myTime="dateString(item.endDate).substr(11,5)" @updateServicePLus="plusaddservice($event)"/> -->
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <th>Total</th>
                                            <th></th>
                                            <th style="text-align:right">{{totalpice}} €</th>
                                            <th>Prix total</th>
                                        </tfoot>
                                    </table>
                                </div>


                                <!--tableau reservation mobile -->
                                <div class="reservation-mobile mobile card p-1">
                                <!-- liste des prestations -->
                                    <div class="card-body position-relative my-1 d-flex justify-content-between align-items-center" v-for="(item, itemid2) in services" :key="itemid2">
                                        <div class="wrapper w-100">
                                            <label title="En construction" class="badge badge-primary construct pointer" style="z-index:1"><i class="mdi mdi-close" @click="removeService(item)"></i></label>
                                            <h6 class="ml-1 mb-1">{{item.name}}</h6>
                                            <div class="d-flex justify-content-between w-100">
                                                <small class="text-muted mb-0">
                                                    <i class="mdi mdi-timelapse  mr-1"></i>{{calculTimes(item.duration)}} <br>
                                                    {{item.price}}€
                                                </small>
                                                <!-- modification de la durée -->
                                                <edit-duration :index="itemid2" :modalKey="'edit'+itemid2" :name="item.name" :duration="calculTimes(item.duration)"
                                                @editDuration="editDuration"
                                                />
                                            </div>
                                        </div>
                                        <h3></h3>
                                    </div>

                                    <!-- ajouter une prestation à la suite -->
                                    <div class="d-flex justify-content-center">
                                        <add-service :modalId="'mobileaddservice'" key="mobileaddservice" v-if="services.length>0" text="Ajouter un rdv à la suite" @updateService="addService($event)"/>
                                    </div>
                                </div>


                                <!-- heure et date de début mobile -->
                                <div class="row mt-3 mobile">
                                        <div class="col-12" v-if="services.length !==0">
                                            <h4>Choisir la date du rendez vous</h4>
                                            <date-picker class="mt-2"
                                            v-model="mobile.date"
                                            :disabled-date="disabledDate"
                                            @change="skey++"
                                            valueType="YYYY-MM-DD"
                                            format="DD-MM-YYYY"
                                            placeholder="Choisir une date"
                                            >
                                        </date-picker>
                                        </div>
                                        <div class="col-12 mt-3" v-if="mobile.date && services.length !== 0">
                                            <h4>Choisir l'heure du rendez vous</h4>
                                            <time-picker-meb class="mt-2"
                                            v-model="mobile.start"
                                            :key="`mobile${mobile.date}${skey}`"
                                            type="input"
                                            :modalId="'modal-mobile-time-picker'"
                                            :duration="totalDuration"
                                            :selectedDateTime="mobile.date.substr(0,10)"
                                            :myDate="myDate"
                                            :open_modal="modaltriger"
                                            :purchases_array="purchases_array"
                                            @saveTime="saveTimeMobile" />
                                        </div>
                                </div>

                                <!-- heure et date de début computer -->
                                <div class="row" v-if="services.length !==0">
                                    <div class="col-md-4 computer">
                                        <div class="d-flex justify-content-center">
                                            <div class="card computer w-100" style="">
                                                <div class="card-header">
                                                    <div data-v-f2b16ec4="" class="wrapper d-flex align-items-center justify-content-center media-info primary"><h2 class="card-title">Horaire</h2></div>
                                                </div>
                                                <div class="card-body">
                                                    <div class="flex-column">
                                                        <div class="form-group">
                                                            <label for="datepicker" class="d-block">Date de réservation</label>
                                                                <date-picker class="mt-2"
                                                                v-model="mobile.date"
                                                                :disabled-date="disabledDate"
                                                                @change="skey++"
                                                                valueType="YYYY-MM-DD"
                                                                format="DD-MM-YYYY"
                                                                placeholder="Choisir une date"
                                                                >
                                                            </date-picker>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="datepicker" class="d-block">Heure de début</label>
                                                                <time-picker-meb class="mt-2"
                                                                    v-model="mobile.start"
                                                                    :key="`${mobile.date}${skey}`"
                                                                    type="input"
                                                                    :modalId="'modal-computer-time-picker'"
                                                                    :duration="totalDuration"
                                                                    :selectedDateTime="mobile.date.substr(0,10)"
                                                                    :myDate="myDate"
                                                                    :purchases_array="purchases_array"
                                                                    @saveTime="saveTimeMobile" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <!-- résumé et validation -->
                                    <div class="col-md-8 col-sm-12">
                                        <!-- résumé de la réservation -->
                                        <div class="border mt-sm-5 h-100 mt-md-0 mt-sm-5 p-5 d-flex flex-column justify-content-between" id="resume">
                                            <span class="mobile">
                                                <h4>Prestations choisies</h4>
                                                <h5 class="text-muted" v-if="services.length == 0">Prestation vide</h5>
                                                <ul v-else>
                                                    <li v-for="(item) in services" :key="item.ulid">{{item.name}}</li>
                                                </ul>
                                            </span>
                                            <span>
                                                <h4>Date du rendez-vous : </h4>
                                                <h5 class="text-muted" v-if="!mobile.date || !mobile.start">
                                                    Pour fixer la date du rendez-vous:
                                                    <ul>
                                                        <li>Compléter les formulaires d'horaire OU</li>
                                                        <li class="computer">double-cliquer sur les colonnes de l'agenda</li>
                                                    </ul>
                                                </h5>
                                                <h5 class="text-muted" v-else> {{getDate(selected_date)}}</h5>
                                            </span>

                                            <!-- Validation de la réservation -->
                                            <div v-if="mobile.date && mobile.start" class="d-flex justify-content-end align-items-end mt-3">
                                                <button class="btn btn-success btn-dynamic-block" v-if="this.$route.params.ulid" v-b-modal.information>Valider la modification</button>
                                                <button class="btn btn-success btn-dynamic-block" v-else v-b-modal.information>Valider la réservation</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                <!--Modal Détails du client -->
                                <b-modal size="md" id="information" hide-footer>
                                    <b-form-group label="Nom du client" label-for="name">
                                        <b-form-input v-model="name"
                                            type="text" id="firstname"
                                            placeholder="Noms et prénoms" size="lg">
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Téléphone" label-for="phone">
                                        <b-form-input v-model="phone"
                                            type="text" id="phone"
                                            placeholder="Numéro téléphone" size="lg">
                                        </b-form-input>
                                    </b-form-group>
                                    <hr>
                                    <div class="d-flex justify-content-end">
                                        <button class="btn btn-link mx-2">Annuler</button>
                                        <!-- <button class="btn btn-success" @click="postMyCart()">Valider</button> -->
                                        <button class="btn btn-success" @click="createCartMobile()">Valider</button>
                                    </div>
                                </b-modal>
                            </div>
                        </div>

                        <!-- FULL CALENDAR / AGENDA -->
                        <span class="">
                            <div class="d-flex flex-md-row flex-sm-column justify-content-between align-items-center mt-5 mb-3">
                                <div>
                                    <p class="mt-5">Les dates disponibles </p>
                                    <!-- <p v-if="services.length !== 0"><b>Double-cliquer dans l'agenda pour choisir la date du rendez-vous</b></p> -->
                                </div>
                                <router-link to="/blockdate">
                                    <button class="btn btn btn-gradient-success btn-fw btn-gradient-info btn-dynamic-block">Indiquer/ Annuler une fermeture </button>
                                </router-link>
                            </div>
                            <fullTimePicker @selected_time="mobile.date = $event,modaltriger = !modaltriger" :key="fullTimePickerKey" @keyCalendar="keyCalendar" @edit-reservation="editReservation($event)"/>
                        </span>

                        
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/fr';
import timePickerMeb from '../../components/time-picker-meb.vue'
// import agendaMeb from '../components/agenda-meb.vue';

import {mapState} from 'vuex'
import axios from 'axios';
import addService from '../../components/functions/add-service.vue';
import pageLoader from '../../components/pageLoader.vue';
// import addServicePlus from '../../components/functions/add-service-plus.vue';
import fullTimePicker from '../../components/functions/full-time-picker-meb.vue';
import editDuration from '../../components/functions/edit-duration.vue';
import { dateMixin } from '../mixins/date';

export default {
    name: 'reservation',
    components : {
        DatePicker,
        timePickerMeb,
        // agendaMeb,
        addService,
        pageLoader,
        // addServicePlus,
        fullTimePicker,
        editDuration
    },
    mixins:[dateMixin],
    data () {
        return {
            // services:null,
            loadershow:false,
            modaltriger:false,
            deliveryinfos:{},
            name:"",
            myDate:[],
            skey:1,
            cart: [
                ],
            search:'',
            phone:'',
            services:[],
            unavailableschedule: [],
            schedule:[],
            mobile:{
                date:'',
                start:'',
                end:''
            },
            fullTimePickerKey:100,
            // edition reservation
            client_name:"",
            purchases_array:[],
            ulid_reservation_to_edit:""
        }
    },
    computed: {
        ...mapState({
            token: state=> state.authentification.token
        }),
        totalpice(){
            let total = 0
            this.services.forEach(element => {
                total = total + element.price
            });
            return total
        },
        isvalid(){
            var check;
            if (this.services.length > 0) {
                check = true
            }else{
                 check = false
            }
            return check
        },
        totalDuration(){
            let duration = 0
            for ( let item of this.services) {
                duration = duration + item.duration
                console.log({duration});
            }
            return this.calculTimes(duration)
        },
        selected_date(){
            return new Date (this.formatDateStringToStandar(this.mobile.date)+' '+ this.mobile.start)
        }
    },
    methods: {
        disabledDate (date) {
            let today = this.datePlusDay(-1)
            return date.getDay()===0 || date.getDay()===1 || date < today
        },
        datePlusDay(nbrofday,date){
            if (!date) {
                date = new Date();
            }
            date =date.setDate(date.getDate() + nbrofday)
            date =new Date(date)
            return date
        },
        async checkOrder(){
                if (!this.isvalid){
                    alert('Choisissez au moins une prestation et veuillez mettre un horaire')
                    return
                }

                // Condition si il y a une réservation faite en parallèle avec les mêmes dates
                const bool = await this.getschedule()
                if (bool) {
                    let text = ""
                    this.unavailableschedule.forEach(element => {
                        text+=" "+this.getDate(element.startDate)+" au "+ this.getDate(element.endDate)
                    });
                    this.loadershow =false
                    this.$swal({
                        text: 'Votre réservation se superpose avec une date déjà existante:'+text+', merci de bien vouloir sélectionner une autre date',
                        showCancelButton: true,
                        confirmButtonColor: '#0b0b0b',
                        // cancelButtonColor: '#fff',
                        // cancelButtonText: 'Annuler',
                        confirmButtonText:'Actualiser'
                    })
                    .then(() => location.reload())
                    return
                }
                // message de retour sur la réservation
                let text2=""
                this.services.forEach(element => {
                    text2+='<div class="d-flex justify-content-between"> <p class="text-left">'+ element.name +'</p> <h3 class="text-right">'+ element.price +'€</h3></div>'
                });
                let textDate = this.cart[0].startDate
             
             if(this.token){
                 this.loadershow = true
                 axios.get('myinfos')
                .then(resGeter => {
                    console.log({resGeter});
                    this.loadershow = false
                    this.deliveryinfos=resGeter.data.address.mainaddress
                    // this.name = resGeter.data.firstname+' '+ resGeter.data.lastname
                    this.$swal({
                        title: 'Voulez-vous confirmer cette réservation?',
                        html: text2+'<br> <hr class="mt-2"><p class="mt-2 text-left">Heure de début: '+this.getDate(textDate)+'</p>',
                        showCancelButton: true,
                        confirmButtonColor: '#1bcfb4;',
                        cancelButtonColor: 'rgb(206, 118, 122)',
                        confirmButtonText: 'Valider',
                        cancelButtonText: 'Annuler'
                    })
                    .then((result) => {
                        if (result.value) {
                        this.loadershow = true;
                        this.postMyCart()
                        }
                    })
                })
                .catch(errGet => {
                    this.loadershow = false
                    console.log({errGet})
                })

                
            }else{
                this.$router.push( {
                name: 'login'
                })
            }
        },
        async postMyCart () {
            const bool = await this.getschedule()
            this.loadershow = true
            if (bool) {
                let text = ""
                this.unavailableschedule.forEach(element => {
                    text+=" "+element.startDate+" "+element.endDate
                });
                this.loadershow =false
                this.$swal({
                    title: 'Une personne a réservé cette date avant vous:'+text+', merci de bien vouloir sélectionner une autre date',
                    showCancelButton: true,
                    confirmButtonColor: '#0b0b0b',
                    cancelButtonColor: '#fff',
                    // cancelButtonText: 'Annuler',
                    confirmButtonText:'Actualiser'
                })
                .then(() => location.reload())
                return
            }
            let body = {
                    deliveryinfos:{
                        name:this.name,
                        phone:this.phone,
                        line1: "64 AV. Saint-Exupery, 81600 Gaillac",
                        city: "Toulouse",
                        postal_code: "any",
                        state:"Av. Saint-Exupéry",
                        country:"France"
                    },
                    shippingprovider:"Chronopost",
                    shippingprice:0,
                    volume:0,
                    weight:0,
                    price:this.totalpice,
                    status:"Sur place",
                    datedelivery:this.dateString(),
                    dateorder:this.dateString()
            }
            if(this.$route.params.ulid){
                await axios.delete(`orders/${this.ulid_reservation_to_edit}`)
                .then(resDeleteOrders =>console.log({resDeleteOrders}))
                .catch(errDeleteOrders=>console.log({errDeleteOrders}))
            }
            axios.post('myorders',body,
                {
                    withCredentials:true,
                        headers: {
                        "X-AUTH-TOKEN": localStorage.getItem("token")
                    }
                })
                .then( resmyroder => {
                     this.mypurchases(resmyroder.data.order)
                    this.services = []
                })
                .catch(errmyorder => {
                    errmyorder
                })
            
        },
        async mypurchases(myorder){
            //get order ulid to the post my order response
            //post mypurchases
            let request = []
            this.cart.forEach(element => {
                   request.push(
                       axios.post('mypurchases', this.purchasesBody(element,myorder),{
                           headers:{
                            'X-AUTH-TOKEN': localStorage.getItem('token')
                        }
                 }))  
            });
            let isdone = null
            isdone = await Promise.all(request).then().catch()
            if(isdone){
                this.$swal({
                title:'Réservation effectuée!',
                text:'La réservation a bien été prise en compte',
                confirmButtonColor: '#0b0b0b',
                confirmButtonText: 'Fermer'
            })
            this.services = [] 
            this.loadershow = false
            // this.$router.go()
            }
           
        },
        purchasesBody(purchases,order){
            //Dynamic body for mypurchases
            let body = {
                    order:order,
                    service:purchases.prestation.ulid,
                    price:purchases.price,
                    quantity:1,
                    datestart:this.dateString(purchases.startDate),
                    dateend:this.dateString(purchases.endDate)
                }
            return body
        },
        async getschedule(){
            this.loadershow =true
            this.message ="Verification des dates"

            // vérification à partir du schedule
            //  await axios.get('datescheduledservices')
            // .then(
            //     resschedule => {
            //         console.log({resschedule})
            //         this.schedule =resschedule.data.result
            //     }
            // )
            // .catch(
            //     errpurchases=> {
            //         errpurchases
            //     }
            // )

            // vérification à partir du purchases avec la restriction de l'ulid sélectionné
             axios.get('purchases',{headers:{"X-AUTH-TOKEN": localStorage.getItem('token')}})
            .then(
                    respurchases => {
                    // this.purchases =respurchases.data.result.filter(ser => ser.product == null && ser.voucher == null);
                    let reservations = respurchases.data.result.filter(ser => ser.product == null && ser.voucher == null);
                    
                    this.purchases = reservations.filter(reservation =>!this.purchases_array.includes(reservation.ulid))


                    let arr= []
                    this.purchases.forEach(element => {
                        arr.push({
                            datestart:element.datestart,
                            dateend:element.dateend
                        })
                    });
                    this.schedule= arr
                }
            )
            .catch(
                errpurchases=> {
                    errpurchases
                }
            )

            if(this.schedule){ 
                this.cart.forEach(cartobject => {
                    this.schedule.forEach(scheduleobject => {
                        if (
                            // schedule contenu dans cart
                            this.dateToNumber(this.dateString(cartobject.startDate)) >= this.dateToNumber(scheduleobject.datestart)  &&  
                            this.dateToNumber(this.dateString(cartobject.startDate)) <= this.dateToNumber(scheduleobject.dateend)  ||
                            // endDate entre le schedule
                            this.dateToNumber(this.dateString(cartobject.endDate))  >= this.dateToNumber(scheduleobject.datestart)  &&
                            this.dateToNumber(this.dateString(cartobject.endDate)) <= this.dateToNumber(scheduleobject.dateend) ||
                            // schedule englobé dans cart
                            this.dateToNumber(this.dateString(cartobject.startDate)) <= this.dateToNumber(scheduleobject.datestart) &&
                            this.dateToNumber(this.dateString(cartobject.endDate)) >= this.dateToNumber(scheduleobject.dateend)
                            ) 
                        {
                            this.unavailableschedule.push(cartobject)
                        }
                    });
                });
            }
            this.message="Traitement des données en cours"
            if (this.unavailableschedule.length > 0 ) {
                return true
            }
            if(this.unavailableschedule.length == 0){
                return false
            }
        },
        dateToNumber(stringDate){
            let date = stringDate.substr(0,16)
            date = date.replace('-','')
            date = date.replace('T','')
            date = date.replace(':', '')
            date = date.replace('-','')
            date = date.replace(' ','')
            date = Number(date)
            return date
        },
        // changement "-" en "/"
         formatDateStringToStandar(date){
            let y = date.substr(0,4)
            let m = date.substr(5,2)
            let d = date.substr(8,2)
            return m+"/"+d+"/"+y
        },
        
        changeTime(item,payload){
            console.log('item dans change time', item);
            console.log('payload dans change time',payload);
            //dynamic date change format to h:m string version
            if (item.endDate) {
                this.myDate = this.myDate.filter(
                    fil => fil.dateend != this.dateString(item.endDate)
                    
                )
            }
            item.endDate = payload.ed
            item.startDate = payload.sd
            this.myDate.push({
                "dateend": this.dateString(payload.ed),
                "datestart":this.dateString(payload.sd)
            })
        },
        // select heure début mobile
        saveTimeMobile(payload){
            let date = payload.sd //format: Tue Mar 22 2022 09:30:00 GMT+0300 (heure normale d’Afrique de l’Est)
            this.mobile.start = date.getHours() + ':' + (date.getMinutes()<10?'0':'') + date.getMinutes()
            // this.mobile.end = date.setHours(date.getHours()+duration)
        },

         // créer le cart automatiquement -- version
        async createCartMobile(){
            this.mobileScreen = true
            this.cart=[]
            let startDate = new Date (this.formatDateStringToStandar(this.mobile.date)+' '+ this.mobile.start)
            let dateNow = new Date()
            // comparaison à date d'aujourd'hui
            if(startDate<dateNow){
                alert('La date et que vous avez sélectionné est déjà passé')
                return
            }
            let endDate = new Date (this.formatDateStringToStandar(this.mobile.date)+' '+ this.mobile.start)
            let price = 0
            let ulid =''
            let datepurchase= this.dateString(startDate)
            for (let item of this.services) {
                ulid = item.ulid
                price = item.price
                startDate = endDate
                endDate = this.datePlusDuration(startDate, this.floatTimeToHHMM(item.duration))
                await this.cart.push({
                    datepurchase:datepurchase,
                    prestation:{
                        ulid:ulid
                    },
                    startDate: startDate,
                    endDate: endDate,
                    price:price
                })
            }
            this.checkOrder()
        },
        datePlusDuration(date,duration){
            let date2 = new Date(date)
            let time = this.GetTimeInDate(date2)
                let hours = Number(duration.substr(0,2)) + Number(time.substr(0,2))
                date2.setHours(hours)
                let minutes = Number(time.substr(3,2)) + Number(duration.substr(3,2)) 
                date2.setMinutes(minutes)
                return date2
        },
        GetTimeInDate(mydate){
            var stringdate;

            if (!mydate) {
                mydate = new Date();    
            }
            stringdate =this.digit(mydate.getHours()) + ":"  
                + this.digit(mydate.getMinutes())  
            return stringdate
        },
        floatTimeToHHMM(floatTime){
            var sec = 3600 * floatTime
            var date = new Date(0);
            date.setSeconds(sec);
            var timeString = date.toISOString().substr(11, 5);
            let heure = Number(timeString.substr(0,2))
            let min = Number(timeString.substr(3,5))
            heure = heure < 10 ? '0' + heure : heure
            min = min < 10 ? '0'+min : min
            let stringHeure = heure+':'+ min
            return stringHeure
        },
        
        sort(){
            this.services.sort((a,b) => {
                if(a.startDate.getTime() > b.startDate.getTime()) return 1;
                if(a.startDate.getTime() < b.startDate.getTime()) return -1;
                return 0;
            });
        },
        addService(services){
            for (let service of services) {
                this.services.push({
                        ulid: service.ulid,
                        name: service.name,
                        duration: service.duration,
                        price: service.price,
                        // données  secondaires
                        quantity: 1,
                        datepurchase: this.dateString(),
                        endDate: "",
                        startDate: ""
                })
            }
        },
        plusaddservice(data){
            let service = data.service
            //repopulate cart 
           this.services.push({
                ulid: service.ulid,
                    name: service.name,
                    duration: this.calculTimes(service.duration),
                    price: service.price,
                    // données  secondaires
                    quantity: 1,
                    datepurchase: data.datepurchase,
                    endDate: data.ed,
                    startDate: data.sd
            })
            this.myDate.push({
                "dateend": this.dateString(data.ed),
                "datestart":this.dateString(data.sd)
            })
            
        },
        dateString(mydate){
            var stringdate;

            if (!mydate) {
                mydate = new Date();    
            }
            stringdate =  mydate.getFullYear() + "-"
                + this.digit((mydate.getMonth()+1))  + "-" 
                + this.digit(mydate.getDate()) + " "

                + this.digit(mydate.getHours()) + ":"  
                + this.digit(mydate.getMinutes()) + ":" 
                + this.digit(mydate.getSeconds())

            return stringdate
        },
        digit(time){
            if (Number(time)< 10) {
                time = "0"+time
            }
            return time
        },
        removeService(service){
            this.$swal({
            title: 'Suppression',
            text: 'Voulez-vous supprimer cette prestation?',
            showCancelButton: true,
            confirmButtonColor: '#0b0b0b',
            cancelButtonColor: 'rgb(172, 169, 169)',
            confirmButtonText: 'Oui, Supprimer',
            cancelButtonText: 'Annuler',
            })
            .then((result) => {
            if (result.value) {
            //remove service in cart
            this.services.splice(this.services.indexOf(service), 1);
                this.$swal(
                {
                title:'Supprimé!',
                text:'La prestation a bien été supprimée',
                icon:'success',
                confirmButtonColor: '#0b0b0b',
                confirmButtonText: 'Fermer'
                }
                )          
            }
        })
        },
        checkDate(item){
            let ed = true
            let cart = this.services.filter( el  => item.datepurchase  == el.datepurchase)
            cart.forEach(element => {
                if (element.endDate == "") {
                    ed = false
                }
            })
            return cart[cart.length - 1] == item && ed
        },
        blockdate(){
            this.$router.push({
                name:"blockdate"
            })
        },
        keyCalendar(payload){
            this.fullTimePickerKey=payload
        },
        editDuration(duration,index){
            this.services[index].duration = Number(this.TimeToFloat(duration))
        },
        // EDITION RESERVATION 
        getOrdersDetails(ulid, date, start, autoEdit){
            axios.get(`orders/${ulid}`)
            .then(resGetOrdersDetails => {
                let orders=resGetOrdersDetails.data
                if(date && start){
                    this.mobile.date = new Date(date).toISOString()
                    this.mobile.start = start
                }
                this.name = orders.deliveryinfos.name
                this.phone = orders.deliveryinfos.phone
                let purchases=resGetOrdersDetails.data.purchases
                // this.purchases_array=[]
                for (let purchase of purchases) {
                    this.purchases_array.push(purchase.ulid)
                    this.services.push({
                            ulid: purchase.service.ulid,
                            name: purchase.service.name,
                            duration: purchase.service.duration,
                            price: purchase.service.price,
                            // données  secondaires
                            quantity: 1,
                            datepurchase: this.dateString(),
                            endDate: "",
                            startDate: ""
                    })
                }
                if(autoEdit){
                    this.createCartMobile()
                    this.deleteReservation(ulid)
                }
                this.loadershow =false
            })
            .catch(errOrdersDetails => {
                errOrdersDetails
                // if(errOrdersDetails.response.status == 404){
                //     this.notFound = true
                //     this.loadershow =false
                //     return
                // }
            })
        },
        // edition direct via le modal de fullTimePicker
        async editReservation(event){
            this.services=[] //videna aloha sao misy ambina reservation
            // console.log("edit reservation dans reservation",event);
            let autoEdit = true
            await this.getOrdersDetails(event.ulid,event.date,event.start,autoEdit) 
        },
        deleteReservation(ulid){
            axios.delete(`orders/${ulid}`)
            .then(resDelete => console.log({resDelete}))
            .catch(errDelete => console.log({errDelete}))
        }
    },
    created() {
    },
    mounted(){
        if(this.$route.params.ulid){
            this.ulid_reservation_to_edit = this.$route.params.ulid
            let ulid = this.$route.params.ulid
            this.getOrdersDetails(ulid)
        }
    }
}
</script>

<style scoped>
    .cream-background {
        position: relative;
        min-height: 30rem;
        background-image: white;
        background-size: 80%;
        background-position:top -17rem right -4rem;
        background-repeat: no-repeat;
    }
.accordion.accordion-bordered .card .card-header a::before {
    color: #e0d075;
}
/* mettre à la ligne lorsque la table est pleine */
td {
    white-space: break-spaces
}
/* reservation mobile */
.reservation-mobile .card-body{
    /* background: white; */
    box-shadow: 1px 3px 5px gainsboro;
    border-radius: 4px;
}
.construct{
height: 25px;
width: 25px;
border-radius: 50%;
position: absolute;
right: -10px;
top: -7px;
font-size: 17px;
padding-right: 0px;
padding-left: 0.5px;
padding-bottom: 0px;
padding-top: 3px;
}
.primary {
    color: #b66dff
}
@media screen and (max-width: 992px) {
    .btn-dynamic-block{
        width: 100%
    }
}
::v-deep .vuecal__event{
    cursor: pointer;
}
</style>