<template>
    <section>
        <div class="row d-flex justify-content-center">
            <div v-if="loader">
                <div class="jumping-dots-loader "><span></span><span></span><span></span></div>
                <span>En cours de chargement</span> 
            </div>
            <div v-else class="col-md-12 time">
                <VueCal
                :key="vueCalKey"
                :style="eventsStyle+' '+ 'vuecal__event'"
                :time-from="7 * 60"
                :time-to="21 * 60"
                :time-step="15"
                @cell-click="getTime"
                :selected-date="dateString()"
                :events="events"
                locale="fr"
                :hideWeekdays="[1,7]"
                :disableViews="disableViews"
                :clickToNavigate="false"
                :dblclickToNavigate="false"
                :on-event-click="eventInfo"
                :editable-events="{resize: true, drag: false, create:false, title:true}"
                :snapToTime="10"
                @event-duration-change="editAfterResize($event)"
                show-time-cells
                >
                <!-- :on-event-dblclick="onEventClick" //paramètre dans VueCal -->
                    <template v-slot:event="{ event }">
                        <!-- <div class="event-content" :style="{ backgroundColor: event.backgroundColor, color: event.color }"> -->
                        <div class="event-content" :style="{ background: event.background, color: event.color }">
                            <div class="vuecal__event-title vuecal_title" v-html="event.title"></div>
                            <hr /><em class="vuecal__event-time"><span> De {{ event.start.formatTime() }}</span> <strong>à</strong> <span>{{ event.end.formatTime() }}</span></em>
                        </div>
                    </template>
                </VueCal>
            </div>
        </div>

        <b-modal id="modal"  hide-footer :title="'Réservation de ' + selected2.name" static>
            <div class="card">
                <div class="d-flex justify-content-around align-items-center m-2">
                        Le <input class="mx-4 form-control" type="date" v-model="selected2.date">
                        <span>à</span>
                        <input class="form-control" type="time" v-model="selected2.r_start_hours">
                </div>
                <!-- <div class="card-body" >
                    <h3>Date début</h3>
                    <p>{{fullDateFr(selected2.start)}}</p>
                    <h3>Date Fin</h3>
                    <p>{{fullDateFr(selected2.end)}}</p>
                </div> -->
                <hr>
                <div class="d-flex justify-content-end my-3">
                    <!-- <button class="btn btn-link" @click="$bvModal.hide('modal')">Annuler</button> -->
                    <!-- validation modification si réservation -->
                    <button class="btn btn-success mx-4" @click="editReservation(selected2)"><i class="fa fa-save"></i> Enregistrer</button>
                    <button v-if="selected2.title=='FERMETURE - fermeture de la boutique'" class="btn btn-danger mx-4" @click="deleteClosingDate(selected2.ulidPurchases)" title="Cette action effacera cette fermeture de date">Supprimer</button>
                    <button v-else class="btn btn-primary mx-4" @click="onEventClick(selected2)"><i class="fa fa-eye"></i> Détails</button>
                </div>

                <div v-if="isload" class="jumping-dots-loader">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </b-modal>
    </section>
</template>
<script>


import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import 'vue-cal/dist/i18n/fr.js'

import axios from 'axios';
// import { mapState } from 'vuex'
// import dateMixin from '../../pages/mixins/date.js'
import { dateMixin } from '@/pages/mixins/date.js'
export default {
    name:'full-time-picker-meb',

    mixins:[dateMixin],
    components:{
        VueCal,
        // mapState
    },
    data(){
        return {
            purchases:[],
            service:[],
            events: [],
            loading: true,
            disabled:"years",
            selected:{},
            loader:true,
            selected2:{
                start: new Date(),
                end: new Date(),
                ulid:'',
                ulidPurchases:'',
                r_start_hours:'',
                r_end_hours:''
            },
            isload:false,
            vueCalKey:111,
            editableEvent:[]
        }
    },
    computed:{
        eventsStyle(){
            return "background-color: red"
        },
        disableViews(){
            if(this.$device.windows){
                return ['years','year']
            }else{
                return ['years','year','month','week']
            }
        }
    },
    methods:{
        editAfterResize(event){
            console.log(event);
            let index = null;
            if (this.editableEvent.length > 0) {
                 index =this.editableEvent.findIndex(
                        element =>  {
                            if (element.ulidPurchases == event.event.ulidPurchases) {
                                return true
                            }
                            return false
                        }
                    )
                    if(index!= null &&  index >= 0){
                        this.editableEvent[index] = event.event
                    }else{
                        this.editableEvent.push(event.event)
                    }
                   
            }else{
                this.editableEvent.push(event.event)
            }
            this.$swal({
                title: 'Modifier le rendez-vous?',
                text: "Voulez-vous modifier l'heure de cette prestation? ",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#260d4f',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, Modifier!',
                cancelButtonText: 'Annuler'
            }).then((result) => {
                if (result.value) {
                    this.saveEdition()
                    // rafraichissement de l'agenda
                    this.vueCalKey++
                    this.getService()
                }
            })
        },
        async saveEdition(){
            this.loader = true
            this.editableEvent.forEach(element => {
                axios.put('purchases/'+element.ulidPurchases,
                {
                    order: element.ulid,
                    datestart: this.dateString(element.start),
                    dateend: this.dateString(element.end),
                    service:element.ulid_service
                }
                )
                .then(() => this.$swal(
                    'Succès!',
                    'L\'horaire du rendez-vous a été modifié',
                    'success'
                ))
                .catch()
            });
            await this.getPurchases()
        },
        editReservation(reservation){
            console.log("reservation", reservation);
            this.$emit('edit-reservation',{
                ulid:reservation.ulid,
                date:this.selected2.date,
                start:this.selected2.r_start_hours
            })
        },


        // formatage date DD-MM-YYYY HH:MM
        getTime(data){
            let date = data.toISOString().split('T')[0]
            //console.log(date)
            this.$emit('selected_time',date)
        },
        formatDate(dateString){
            // let date = new Date(dateString)
            // date = date.toISOString().split('T')[0]+' '+ date.toISOString().split('T')[1].slice(0,5)
            // return date
            // prendre le décalage horaire
            
            let utc = dateString.charAt(21)
            // utc == heure 
            let date = new Date(dateString)
            let originalUTC = date.toISOString().split('T')[1].slice(0,5)
            let getHour = parseInt(originalUTC.split(':')[0])
            let displayedHour = getHour + parseInt(utc)

            // date = date.toISOString().split('T')[0]+' '+ date.toISOString().split('T')[1].slice(0,5)
            date = date.toISOString().split('T')[0]+' '+ displayedHour.toString()+date.toISOString().split('T')[1].slice(2,5)
            return date
        },
        dateString(mydate){
            var stringdate;

            if (!mydate) {
                mydate = new Date();    
            }
            stringdate =  mydate.getFullYear() + "-"
                + this.digit((mydate.getMonth()+1))  + "-" 
                + this.digit(mydate.getDate()) + " "

                + this.digit(mydate.getHours()) + ":"  
                + this.digit(mydate.getMinutes()) + ":" 
                + this.digit(mydate.getSeconds())

            return stringdate
        },
        digit(time){
            if (Number(time)< 10) {
                time = "0"+time
            }
            return time
        },
        getService() {
            axios.get('services')
            .then(
                resService=> {
                    this.service= resService.data.result
                    this.getPurchases()
            })
            .catch(errService=> console.log(errService))
      },
      getPurchases(){
          axios.get('purchases',{headers:{'X-AUTH-TOKEN':localStorage.getItem('token')} })
                .then(
                    respurchases => {
                        console.log('respurchases',respurchases)

                        this.purchases =respurchases.data.result.filter(ser => ser.service != null);
                        console.log('this.purchases',this.purchases)
                        this.createEvent(this.purchases)
                        
                        this.loader = false
                    }
                )
                .catch(
                    errpurchases=> {
                        errpurchases
                    }
                )
      },
      createEvent(data){
        // actualisation de l'events
        this.events=[]
          let hashColor ="repeating-linear-gradient(45deg, transparent, transparent 10px, #f2f2f2 10px, #f2f2f2 20px)"
          let ordorUnique = ""
          let bgColor ="#"+(Math.floor(Math.random()*16777215).toString(16))
          let color = '#fff'
          data.forEach(element => {
              if (element.order.ulid == ordorUnique) {
                  // im cool right
              }else{
                  bgColor = element.order.ulid=="01FY46XE8PN27S18ZJYS2GEFKZ"?hashColor:"#"+(Math.floor(Math.random()*16777215).toString(16))
                  color = element.order.ulid=="01FY46XE8PN27S18ZJYS2GEFKZ"?'#000':'#fff'
                  ordorUnique = element.order.ulid
              }
              this.events.push({
                start:this.formatDate(element.datestart),
                end:this.formatDate(element.dateend),
                title:this.getServiceName(element.service.ulid)+' - '+element.order.deliveryinfos.name,
                name: element.order.deliveryinfos.name,
                color: color,
                background: bgColor,  
                ulid:element.order.ulid,
                ulidPurchases:element.ulid,
                ulid_service:element.service.ulid
              })
          })
          this.loading = false // solution de secours
      },
      getServiceName(ulid){
          let serv = this.service.filter(ser => ser.ulid == ulid)
          return serv[0].name
      },
      onEventClick(event){
          if(event.title=="FERMETURE - Laurie Chonion"){
            return
            }
            this.ordersDetails(event.ulid)
      },
      eventInfo(event, e){
        //   console.log('event',event);
          console.log('event',event);
          console.log('e',e);
          // Prevent navigating to narrower view (default vue-cal behavior).
          e.stopPropagation()
          this.selected2 = {
            ...event,
            date:this.formatDate3(event.start.toISOString()),
            r_start_hours:event.start.toTimeString().slice(0,5),
            r_end_hours:event.end.toTimeString().slice(0,5)

          } 
          this.$bvModal.show('modal')
      },
      ordersDetails(ulid){
          this.$router.push({
              name:"ordersDetails",
              params:{
                  ulid:ulid
              }
          })
      },
      deleteClosingDate(ulidPurchase){
          this.$swal({
                title: 'Voulez-vous supprimer cette fermeture?',
                showCancelButton: true,
                confirmButtonColor: '#1bcfb4;',
                cancelButtonColor: 'rgb(206, 118, 122)',
                confirmButtonText: 'Valider',
                cancelButtonText: 'Annuler'
            })
            .then((result) => {
                if (result.value) {
                this.isload = true;
                this.deletePurchase(ulidPurchase)
                }
            })
      },
      deletePurchase(ulidPurchase){
          axios.delete(`purchases/${ulidPurchase}`)
          .then(resDeleteClosingDate => {
              console.log(resDeleteClosingDate);
              location.reload()
            //   this.getService()
            //   this.$bvModal.hide("modal")
          })
          .catch(errDeleteClosingDate => console.log(errDeleteClosingDate))
      },
      keyCalendar(){
          this.$emit('keyCalendar', this.vueCalKey++)
      }
    },
    mounted(){
      this.getService()
      this.$device
      console.log('this', this.$device);
    }
}
</script>
<style scoped>
:root{
--randomColor:
}
.randomColor{
    background-color: --randomcolor;
}
.time{
    min-height: 50rem;
}
::v-deep .orange{
    background: #cda525;
    color: #fff;
}
::v-deep .closed{
    background: #ffa4a4;
    color: #fff;
}
.vuecal_title{
    text-shadow: 4px 3px 9px rgba(0,0,0,0.6);
    /* text-shadow: 3px 0px 2px rgba(0,0,0,0.76); */
}
.event-content{
    height: 99%;
    border-radius: 4px;
    padding: 4px;
}

::v-deep .vuecal__flex.vuecal__cell-content:hover {
    transform: scaleX(1.03);
    background: #FEF6D8;
    cursor: pointer;
}
::v-deep .vuecal__flex.vuecal__cell-content:focus {
    transform: scaleX(1.03);
    /* background: #FAFAFA; */
    background: #EFB90B;
    cursor: pointer;
}
</style>